import React, { createContext, useEffect, useState } from "react";
import 'react-multi-carousel/lib/styles.css';
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import FrontLayoute from "./front/component/layout/FrontLayoute";
import Home from "./front/pages/home/Home";
import Service from "./front/pages/Service";
import About from "./front/pages/About";
import Contact from "./front/pages/Contact";
import AdminLayoute from "./admin/component/layoute/AdminLayoute";
import Dashboard from "./admin/pages/dashboard/Dashboard";
import 'boxicons'
import AdminService from "./admin/pages/service/AdminService";
import AddService from "./admin/pages/service/AddService";
import EditService from "./admin/pages/service/EditService";
// import Impact from "./admin/pages/impact/Impact";
// import Addimpact from "./admin/pages/impact/Addimpact";
// import Editimpact from "./admin/pages/impact/Editimpact";
// import Admintestimonial from "./admin/pages/testimonial/Admintestimonial";
// import Addtestimonial from "./admin/pages/testimonial/Addtestimonial";
// import EditTestimonial from "./admin/pages/testimonial/EditTestimonial";
import AdminEnquiry from "./admin/pages/enquiry/AdminEnquiry";
import Setting from "./admin/pages/Setting";
import AdminLogin from "./admin/pages/AdminLogin";
import Profile from "./admin/pages/account/Profile";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubscribesEmail from "./admin/pages/subscribers/SubscribesEmail";
// import AdminMarketclient from "./admin/pages/marketclient/AdminMarketclient";
// import AddMarketclient from "./admin/pages/marketclient/AddMarketclient";
// import EditMarketClient from "./admin/pages/marketclient/EditMarketClient";
import { Navigate } from 'react-router-dom';
// import ServiceSinglepage from "./front/pages/ServiceSinglepage";
import { apibaseURl,filebasepath,apibaseURlweb} from  "./Function"
import axios from "axios";
import Privacypolicy from "./front/pages/Privacypolicy";
import TermsConditions from "./front/pages/TermsConditions";
// import Thankyou from "./front/pages/Thankyou";
import Sliderfor from "./admin/pages/slider/Sliderfor";
import Addslider from "./admin/pages/slider/Addslider";
import EditSlider from "./admin/pages/slider/EditSlider";
// import Project from "./admin/pages/Project/Project";
// import AddProject from "./admin/pages/Project/AddProject";
// import EditProject from "./admin/pages/Project/EditProject";
import Projectpage from "./front/pages/Projectpage";
import ProjectSinglepage from "./front/pages/ProjectSinglepage";
import PagenotFound from "./front/pages/PagenotFound";
import Seo from "./admin/pages/seo/Seo";
import Seoupdate from "./admin/pages/seo/Seoupdate";
import ScrolltoTop from "./ScrollToTop";
import BookingNow from "./front/pages/BookingNow";
import AdminBookingNow from "./admin/pages/bookNow/AdminBookingNow";
import BookingNowModel from "./front/pages/BookingNowModel";



export  const UserContext = createContext()

// protected routes start =============================================>
const Protectedroute = ({ children }) => {
  const data = localStorage.getItem("user");
  if (data) {
    return children;
  }
  return <Navigate to="/admin/adminlogin" />;
};


window.addEventListener("storage", (e) => {
  if (e.key === "user" && e.newValue === null) {
    window.location.reload();
  }
});



const AddInlineScriptToHead = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    
    // Set the inline script content
    script.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-16594954469/f1F2CJGH3NAZEOXRi-k9'});
    `;

    // Append the script to the <head> tag
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything
};

// protected routes end =============================================>

function App() {
  const [isLogout , setisLogout] = useState(false);
  const [datasetting, setDatasetting] = useState({});
  const [servicedata, setServicedata] = useState([]);
  const[featuredata,setFeaturedata] = useState("")
  const[sliderdata,setSliderdata] =useState([])
  const [favicon, setFavicon] = useState('');
  const [projectdata, setProjectdata] = useState([]);
  const[metakeyword,setMetakeyword]=useState("");
  const[metadescription,setMetadescription]=useState([]);

 
    // setting api call ==============================>

    const fetchsettingdata = async () => {
      try {
        const response = await axios.get(`${apibaseURl}settings`)
        if (response.status == 200) {
          if (response?.data.status == true) {
            setDatasetting(response.data.data);
            setFavicon(response.data.data.site_icon)
            document.title = response.data.data.site_title;
            document.getElementById("demo").innerHTML = document.title; 
          }
        }
      } catch (error) {
        
      }
    };
    useEffect(() => {
      fetchsettingdata();
    }, []);

    useEffect(() => {
      const link = document.querySelector('link[rel="shortcut icon"]');
      link.href = filebasepath + favicon;
    }, [favicon]);


  // setting api call end ==============================>

  const showseodata = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}seo`);
    
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setMetadescription(responce.data.data);
 
        }
      }
    } catch (error) {
 
    }
  };

  useEffect(() => {
    showseodata();
  }, []);

// slider api call ==========================================================>
const SliderShowdatafront = async () => {
  try {
    const responce = await axios.get(`${apibaseURlweb}slider`);
    if (responce.status === 200) {
      if (responce?.data?.status === true) {
        setSliderdata(responce.data.data);
      }
    }
   }catch (error) {
   
  }
};

useEffect(() => {
  SliderShowdatafront();
}, []);


// project show for frontend =========================================>
const projectshowfront = async () => {
  try {
    const responce = await axios.get(`${apibaseURlweb}project`);
    if (responce.status === 200) {
      if (responce?.data?.status === true) {
        setProjectdata(responce.data.data);
      }
    }
   }catch (error) {
   
  }
};
useEffect(() => {
  projectshowfront();
}, []);

  // service api call ===================================================>
  const showservicedata = async () => {
    try {
      const response = await axios.get(`${apibaseURlweb}services`);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          if(featuredata == true){
            const isFeaturedservices = response.data.data.filter(item => item.service_featured === "Yes");
            setServicedata(isFeaturedservices);
          }else {
            setServicedata(response.data.data);
          }
        }
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    showservicedata();
  }, [featuredata]);

  // service api end ===================================================>
  const logout = () => {
    localStorage.removeItem("user")
    setisLogout(true)
  };
  const userdatacontext={
    logout:logout,
  }


  return (
    <>
    
      <ToastContainer />
        <UserContext.Provider value={userdatacontext}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrolltoTop/>
          {/* <BookingNowModel datasetting={datasetting}/> */}
            <Routes>

          <Route path="/" element={<FrontLayoute
          
           datasetting={datasetting}
            setMetakeyword={setMetakeyword}
            setMetadescription={setMetadescription} />}>
            <Route path="/" element={<Home
             servicedata={servicedata} 
             datasetting={datasetting}
              setFeaturedata={setFeaturedata}
              sliderdata={sliderdata}
              metadescription={metadescription}
          
              />}/>
            <Route path="/services"  element={<Service
              servicedata={servicedata} 
              datasetting={datasetting}
              setFeaturedata={setFeaturedata }
              metadescription={metadescription}
            />}/>
            <Route path="/about-us" element={<About  metadescription={metadescription}/> }/>
            <Route path="/contact-us" element={<Contact 
            datasetting={datasetting}
            metadescription={metadescription}
            />}/>
            {/* <Route path="services/:slug" element={<ServiceSinglepage datasetting={datasetting}/>}/> */}
            <Route path="/projects" element={<Projectpage
             projectdata={projectdata}
             metadescription={metadescription}
             />}/>
            <Route path="/projects/:slug" element={<ProjectSinglepage/>}/>
            <Route path="/booking-now" element={<BookingNow  datasetting={datasetting}
            AddInlineScriptToHead={AddInlineScriptToHead}
            />}/>
            {/* <Route path="/thankyou" element={<Thankyou  datasetting={datasetting}/>}/> */}
            <Route path="/privacy-policy" element={<Privacypolicy  metadescription={metadescription}/>}/>
            <Route path="/terms-conditions" element={<TermsConditions  metadescription={metadescription}/>}/>
            <Route path="/*" element={<PagenotFound/>}/>
          </Route>
           {/* admin routes ========================================>*/}
           <Route path="admin" element={<Protectedroute>
            <AdminLayoute logout={logout} datasetting={datasetting}/>
            </Protectedroute> }>
            <Route path="dashboard" element={<Dashboard/>}/>
            <Route path="profile" element={<Profile/>}/>

            <Route path="sliderfor" element={<Sliderfor/>}/>
            <Route path="sliderfor/addslider" element={<Addslider/>}/>
            <Route path="sliderfor/editslider/:id" element={<EditSlider/>}/>

            {/* <Route path="project" element={<Project/>}/>
            <Route path="project/addproject" element={<AddProject/>}/>
            <Route path="project/editproject/:id" element={<EditProject/>}/> */}
            <Route path="seo-mata" element={<Seo/>}/>
            <Route path="seo-mata/seo_edit/:id" element={<Seoupdate/>}/>



            <Route path="adminservice" element={<AdminService/>}/>
            <Route path="adminservice/addservice" element={<AddService/>}/>
            <Route path="adminservice/editservice/:id" element={<EditService/>}/>

            {/* <Route path="impact" element={<Impact/>}/>
            <Route path="impact/addimpact" element={<Addimpact/>}/>
            <Route path="impact/editimpact/:id" element={<Editimpact/>}/> */}

            {/* <Route path="admintestimonial" element={<Admintestimonial/>}/>
            <Route path="admintestimonial/addtestimonial" element={<Addtestimonial/>}/>
            <Route path="admintestimonial/edittestimonial/:id" element={<EditTestimonial/>}/> */}

            {/* <Route path="adminmarketclient" element={<AdminMarketclient/>}/>
            <Route path="adminmarketclient/addmarketclient" element={<AddMarketclient/>}/>
            <Route path="adminmarketclient/editmarketclient/:id" element={<EditMarketClient/>}/> */}

            <Route path="adminenquery" element={<AdminEnquiry/>}/>
            <Route path="bookings" element={<AdminBookingNow/>}/>
            <Route path="subscribesemail" element={<SubscribesEmail/>}/>
            
            <Route path="setting" element={<Setting/>}/>
   
           </Route>
           <Route path="/admin/adminlogin" element={<AdminLogin datasetting={datasetting}/>}/>
        </Routes>
         </BrowserRouter>
       </UserContext.Provider>
    </>
  );
}

export default App;
