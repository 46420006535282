import React  from 'react';
import enqueryimg from '../../front/assest/img/illustrations/book_now.png'
import BookingInfoset from './home/BookingInfoset';

const BookingNow = ({datasetting, AddInlineScriptToHead}) => {

    return (
        <>
            <AddInlineScriptToHead />
            <section className='pb-0'>
                <div className="container mt-5">
                    <div className=" mb-6 text-center">
                   
                        <h2 className='sub_heding pt-5'>Book Your Technician</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-sm-12 col-md-6 col-lg-6 col-xl-6 order-2 order-lg-1 mt_bar_for_contact">
                             <BookingInfoset islabel={true} datasetting={datasetting}/>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 order-1 order-lg-2">
                            <div>
                                <img src={enqueryimg} alt="..." width="100%" style={{ borderRadius: "5px" }} />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default BookingNow;
